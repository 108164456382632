/* eslint-disable no-console */

interface HomiesLogger {
  debug(message: string, ...optionalParams: any[]): void;
  info(message: string, ...optionalParams: any[]): void;
  error(message: string, ...optionalParams: any[]): void;
  warn(message: string, ...optionalParams: any[]): void;
}

const defaultLogger = {
  debug(message: string, ...optionalParams: any[]) {
    console.debug(message, ...optionalParams);
  },
  info(message: string, ...optionalParams: any[]) {
    console.info(message, ...optionalParams);
  },
  error(message: string, ...optionalParams: any[]) {
    console.error(message, ...optionalParams);
  },
  warn(message: string, ...optionalParams: any[]) {
    console.warn(message, ...optionalParams);
  }
} as HomiesLogger;

export default defaultLogger;

export function createLogger(categoryName: string): HomiesLogger {
  const logPrefix = `[${categoryName}]`;
  return {
    debug(message, ...optionalParams) {
      defaultLogger.info(`${logPrefix} ${message}`, ...optionalParams);
    },
    info(message, ...optionalParams) {
      defaultLogger.info(`${logPrefix} ${message}`, ...optionalParams);
    },
    warn(message, ...optionalParams) {
      defaultLogger.info(`${logPrefix} ${message}`, ...optionalParams);
    },
    error(message, ...optionalParams) {
      defaultLogger.info(`${logPrefix} ${message}`, ...optionalParams);
    }
  };
}
