import axios, { AxiosRequestConfig } from 'axios';
import firebaseService from '@/services/firebase.service';

async function getRequestHeaders() {
  if (!firebaseService.auth.instance.currentUser) {
    return;
  }

  const token = await firebaseService.auth.getTokenAsync();

  return {
    Authorization: `Bearer ${token}`,
    'Authorization-Type': 'Firebase'
  };
}

export default {
  async getAsync<T>(endpoint: string, query?: any) {
    const config = {
      params: query,
      headers: await getRequestHeaders()
    } as AxiosRequestConfig;

    const response = await axios.get<T>(endpoint, config);

    return response.data;
  },
  async postAsync<T>(endpoint: string, data?: any) {
    const config = {
      headers: await getRequestHeaders()
    } as AxiosRequestConfig;

    const response = await axios.post<T>(endpoint, data, config);

    return response.data;
  },
  async deleteAsync<T>(endpoint: string, data?: any) {
    const config = {
      headers: await getRequestHeaders()
    } as AxiosRequestConfig;

    const response = await axios.delete<T>(endpoint, {
      ...config,
      data
    });

    return response.data;
  }
};
