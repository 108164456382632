<script setup lang="ts">
import firebaseService from '@/services/firebase.service';
import { ref } from 'vue';

const loggingIn = ref(false);

async function login() {
  loggingIn.value = true;

  await firebaseService.auth.loginAsync();
}
</script>

<template>
  <v-app>
    <v-main>
      <v-container
        class="fill-height d-flex flex-column justify-center align-center"
      >
        <h1 class="text-h5">Welcome to Homies!</h1>
        <p class="text-subtitle-1 mb-4">Please sign in before continuing.</p>
        <v-btn
          :loading="loggingIn"
          prepend-icon="mdi-google"
          text="Sign in with Google"
          @click="login"
        />
      </v-container>
    </v-main>
  </v-app>
</template>
