import { RouteRecordRaw } from 'vue-router';

export default [
  {
    name: 'home',
    path: '/home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/finances',
    children: [
      {
        name: 'finance.timeline',
        path: '',
        component: () => import('@/views/finance/TimelineView.vue')
      }
    ]
  },
  {
    path: '/households',
    // component: () => import('@/views/HouseholdsView.vue'),
    children: [
      {
        name: 'households',
        path: '',
        component: () => import('@/views/households/IndexView.vue')
      },
      {
        path: ':id',
        props: true,
        component: () => import('@/views/households/DetailsView.vue'),
        children: [
          {
            name: 'households.details',
            path: '',
            component: () => import('@/views/households/details/IndexView.vue')
          },
          {
            name: 'households.details.members',
            path: 'members',
            component: () =>
              import('@/views/households/details/MembersView.vue')
          },
          {
            path: 'finances',
            component: () =>
              import('@/views/households/details/FinancesView.vue'),
            children: [
              {
                name: 'households.details.finances.categories',
                path: 'categories',
                component: () =>
                  import(
                    '@/views/households/details/finances/CategoriesView.vue'
                  )
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'settings',
    path: '/settings'
  },
  {
    name: 'invite',
    path: '/invite/:token',
    props: true,
    component: () => import('@/views/AcceptInviteView.vue')
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/home'
  }
] as RouteRecordRaw[];
