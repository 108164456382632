import CreateHouseholdRequest from '@/models/requests/households/CreateHouseholdRequest';
import GetHouseholdInviteLinkResponse from '@/models/responses/households/GetHouseholdInviteLinkResponse';
import GetHouseholdsResponse from '@/models/responses/households/GetHouseholdsResponse';
import GetUserHouseholdDetailsResponse from '@/models/responses/households/GetUserHouseholdDetailsResponse';
import httpService from '@/services/http.service';

export default {
  async getHouseholdsAsync(): Promise<GetHouseholdsResponse> {
    return httpService.getAsync<GetHouseholdsResponse>('/api/v1/households');
  },
  async getHouseholdDetailsAsync(
    id: string
  ): Promise<GetUserHouseholdDetailsResponse> {
    return httpService.getAsync<GetUserHouseholdDetailsResponse>(
      `/api/v1/households/${id}`
    );
  },
  async getInviteLinkAsync(id: string) {
    return httpService.getAsync<GetHouseholdInviteLinkResponse>(
      `/api/v1/households/${id}/invite`
    );
  },
  async leaveHouseholdAsync(id: string) {
    await httpService.postAsync(`/api/v1/households/${id}/leave`);
  },
  async deleteHouseholdAsync(id: string) {
    await httpService.deleteAsync(`/api/v1/households/${id}`);
  },
  async createHouseholdAsync(request: CreateHouseholdRequest): Promise<void> {
    await httpService.postAsync('/api/v1/households/create', request);
  },
  async removeUserAsync(householdId: string, uid: string): Promise<void> {
    await httpService.postAsync(
      `/api/v1/households/${householdId}/members/${uid}/remove`
    );
  }
};
