import householdsApi from '@/api/households.api';
import arrayUtils from '@/utils/array.utils';
import { defineStore } from 'pinia';

const useHouseholdsStore = defineStore('households', {
  state() {
    return {
      households: [] as Household[],
      currentHouseholdId: undefined as string | undefined
    };
  },
  actions: {
    async refreshAsync() {
      const response = await householdsApi.getHouseholdsAsync();

      arrayUtils.setArray(this.households, response.households);

      const savedHousehold = localStorage.getItem('current-household');

      if (!this.currentHouseholdId && this.households.length > 0) {
        // TODO: store this somewhere..

        let newHousehold = savedHousehold;

        if (
          !newHousehold ||
          !this.households.some((f) => f.householdId === newHousehold)
        ) {
          newHousehold = this.households[0].householdId;
        }

        this.setCurrentHousehold(newHousehold);
      }
    },
    setCurrentHousehold(id: string) {
      this.currentHouseholdId = id;

      localStorage.setItem('current-household', id);
    }
  },
  getters: {
    currentHousehold: (state) =>
      state.households.find((f) => f.householdId === state.currentHouseholdId)
  }
});

export default useHouseholdsStore;
