import { StateTree, Store } from 'pinia';
import { UnwrapRef } from 'vue';

async function waitForStoreAsync<TState extends StateTree>(
  store: Store<string, TState>,
  selector: (store: UnwrapRef<TState>) => any
) {
  return new Promise((resolve) => {
    if (selector(store)) {
      return resolve(selector(store));
    }

    const unwatch = store.$subscribe((_: any, state: UnwrapRef<TState>) => {
      if (selector(state)) {
        unwatch();

        resolve(selector(state));
      }
    });
  });
}

export default {
  waitForStoreAsync
};
