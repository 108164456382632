import * as Sentry from '@sentry/vue';
import { App } from 'vue';
import { createLogger } from './log';

const log = createLogger('services/sentry');

export function install(app: App) {
  const sentryEnabled = import.meta.env.VITE_SENTRY;
  const sampleRate = Number(import.meta.env.VITE_SENTRY_SAMPLE_RATE || '0');
  const sentryEnv = import.meta.env.VITE_SENTRY_ENVIRONMENT || 'Development';

  log.debug('sentry:', {
    sentryEnabled,
    sampleRate,
    sentryEnv
  });

  if (!sentryEnabled) {
    return;
  }

  log.info('initializing sentry');

  Sentry.init({
    app,
    environment: sentryEnv,
    dsn: 'https://4be19124a5ac43019016e8d5d6117bd9@o1242485.ingest.us.sentry.io/4505069572259840',
    release: import.meta.env.VITE_SENTRY_RELEASE,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [window.location.origin],
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    // Performance Monitoring
    tracesSampleRate: sampleRate, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/homies\.ilan\.dev\/api/],
    // Session Replay
    replaysSessionSampleRate: sampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export default {
  setUser(user: { email: string; uid: string }) {
    log.debug(`setting current sentry user to ${user.uid}`);

    Sentry.setUser({
      email: user.email,
      id: user.uid
    });
  },
  clearUser() {
    log.debug('clearing current sentry user');

    Sentry.setUser(null);
  }
};
