<script lang="ts" setup>
import LoginView from '@/views/LoginView.vue';
import { ref } from 'vue';
import useAuthStore from './store/auth';
import { useI18n } from 'vue-i18n';
import useHouseholdsStore from './store/households';
import firebaseService from './services/firebase.service';
import { VSnackbarQueue } from 'vuetify/labs/VSnackbarQueue';
import useAlertsStore from './store/alerts';

const { t } = useI18n();
const auth = useAuthStore();
const households = useHouseholdsStore();
const alerts = useAlertsStore();

const init = ref(false);
const drawerOpen = ref(false);

async function initApp() {
  await auth.initAsync();

  if (auth.loggedIn) {
    await households.refreshAsync();
  }

  console.log('app init');

  init.value = true;
}

async function logout() {
  await firebaseService.auth.logoutAsync();
}

initApp();
</script>

<template>
  <template v-if="init">
    <v-app v-if="auth.loggedIn">
      <v-navigation-drawer v-model="drawerOpen" temporary floating>
        <v-list lines="two" class="py-0">
          <v-list-item>
            <v-list-item-title class="d-block text-truncate">
              {{ auth.displayName! }}
            </v-list-item-title>
            <v-list-item-subtitle class="d-block text-truncate">
              {{ auth.email! }}
            </v-list-item-subtitle>
            <template #prepend>
              <v-avatar>
                <v-img referrerpolicy="no-referrer" :src="auth.photoUrl!" />
              </v-avatar>
            </template>
          </v-list-item>
          <v-divider />
        </v-list>
        <v-list nav lines="one">
          <v-list-subheader title="Current household" />
          <v-list-group :title="households.currentHousehold?.name">
            <template #activator="{ props: groupProps }">
              <v-list-item v-bind="{ ...groupProps }" />
            </template>
            <v-list-item
              v-for="household of households.households"
              :key="household.householdId"
              :title="household.name"
              @click="
                households.setCurrentHousehold(household.householdId);
                drawerOpen = false;
              "
            />
          </v-list-group>
          <template v-if="households.currentHousehold">
            <v-list-subheader title="Your household" />
            <v-list-item
              prepend-icon="mdi-home"
              title="Home"
              :to="{ name: 'home' }"
            />
            <v-list-group title="Finances">
              <template #activator="{ props: groupProps }">
                <v-list-item
                  prepend-icon="mdi-finance"
                  title="Finances"
                  v-bind="{ ...groupProps }"
                />
              </template>
              <v-list-item
                title="Timeline"
                :to="{ name: 'finance.timeline' }"
              />
            </v-list-group>
          </template>
          <v-list-subheader title="Your account" />
          <v-list-item
            prepend-icon="mdi-home-group"
            title="Households"
            :to="{ name: 'households' }"
          />
          <v-list-item
            prepend-icon="mdi-cog"
            title="Settings"
            :to="{ name: 'settings' }"
          />
          <v-list-item
            prepend-icon="mdi-logout"
            title="Log out"
            @click="logout"
          />
        </v-list>
        <template #append>
          <v-container
            fluid
            class="d-flex flex-column justify-center align-center"
          >
            <span class="text-caption"> homies &copy; 2024 </span>
          </v-container>
        </template>
      </v-navigation-drawer>
      <v-app-bar>
        <v-app-bar-nav-icon @click="drawerOpen = true" />
        <v-app-bar-title>
          {{ t('app.title.default') }}
        </v-app-bar-title>
      </v-app-bar>
      <v-main>
        <router-view />
        <v-snackbar-queue v-model="alerts.alerts" />
      </v-main>
    </v-app>
    <login-view v-else />
  </template>
  <v-app v-else>
    <v-main>
      <v-container
        class="fill-height d-flex flex-column justify-center align-center"
      >
        <v-progress-circular indeterminate size="small" />
        <span class="text-subtitle-2 mt-4">
          {{ t('app.loading.subtitle') }}
        </span>
      </v-container>
    </v-main>
  </v-app>
</template>

<style lang="scss">
.no-select {
  user-select: none;
}
</style>
