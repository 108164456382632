/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from './vuetify';
import router from '../router';
import i18n from '@/i18n';
import { createPinia } from 'pinia';

// Types
import type { App } from 'vue';
import initDayjs from './dayjs';
import { install as initSentry } from '@/services/sentry';

export function registerPlugins(app: App) {
  initDayjs();
  initSentry(app);

  app.use(vuetify).use(router).use(createPinia()).use(i18n);
}
