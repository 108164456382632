import { initializeApp } from 'firebase/app';
import {
  getAuth,
  getIdToken,
  getRedirectResult,
  NextOrObserver,
  onAuthStateChanged,
  signInWithRedirect,
  signOut,
  User
} from 'firebase/auth';
import { GoogleAuthProvider } from 'firebase/auth';
import { createLogger } from './log';
import { getAnalytics } from 'firebase/analytics';

const log = createLogger('services/firebase');

const app = initializeApp({
  apiKey: 'AIzaSyC6x6DbXWXo6M1XRiCZUtBT1dIPArhE6oE',
  authDomain: import.meta.env.VITE_AUTHDOMAIN,
  projectId: 'homies-app-dev',
  storageBucket: 'homies-app-dev.appspot.com',
  messagingSenderId: '554634748379',
  appId: '1:554634748379:web:e9ac20230115a3753847fe'
});

log.debug(
  `initialized firebase with auth domain: ${import.meta.env.VITE_AUTHDOMAIN}`
);

export default {
  auth: {
    get instance() {
      return getAuth(app);
    },
    async checkRedirectResultAsync() {
      try {
        await getRedirectResult(this.instance);
      } catch (error) {
        log.error('error while getting redirect result..', {
          error
        });
      }
    },
    async loginAsync() {
      try {
        const provider = new GoogleAuthProvider();

        await signInWithRedirect(this.instance, provider);
      } catch (err) {
        console.log({ err });
      }
    },
    async logoutAsync() {
      await signOut(this.instance);
    },
    setCallback(callback: NextOrObserver<User>) {
      return onAuthStateChanged(this.instance, callback);
    },
    async getTokenAsync(): Promise<string> {
      if (!this.instance.currentUser) {
        throw 'Cannot get token if user is not signed in';
      }

      const token = await getIdToken(this.instance.currentUser);

      return token;
    }
  },
  analytics: {
    instance: getAnalytics(app)
  }
};
