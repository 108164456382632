import { createI18n } from 'vue-i18n';

import en from './locales/en.json';

const messages = {
  en
};

const i18n = createI18n({
  messages,
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'USD',
        notation: 'standard'
      }
    }
  },
  locale: 'en',
  legacy: false
});

export default i18n;
