import { defineStore } from 'pinia';

const useAlertsStore = defineStore('alerts', {
  state() {
    return {
      alerts: [] as string[]
    };
  },
  actions: {
    showAlert(message: string) {
      this.alerts.push(message);
    }
  }
});

export default useAlertsStore;
