function setArray<T>(a: T[] | undefined | null, b?: T[]) {
  if (!a) {
    return;
  }

  a.splice(0, a.length);

  if (!b) {
    return;
  }

  for (const item of b) {
    a.push(item);
  }
}

function removeWhere<T>(a: T[], selector: (item: T) => boolean): T[] {
  let index = a.findIndex(selector);

  while (index >= 0) {
    a.splice(index, 1);

    index = a.findIndex(selector);
  }

  return a;
}

export default {
  setArray,
  removeWhere
};
