import firebaseService from '@/services/firebase.service';
import sentry from '@/services/sentry';
import storeUtils from '@/utils/store.utils';
import { User } from 'firebase/auth';
import { defineStore } from 'pinia';

const useAuthStore = defineStore('auth', {
  state() {
    return {
      init: false,
      uid: undefined as string | undefined,
      displayName: undefined as string | undefined | null,
      email: undefined as string | undefined | null,
      photoUrl: undefined as string | undefined | null
    };
  },
  actions: {
    async initAsync(): Promise<void> {
      if (this.init) {
        return;
      }

      firebaseService.auth.setCallback(this.onAuthStateChanged);

      await firebaseService.auth.checkRedirectResultAsync();

      await storeUtils.waitForStoreAsync(this, (f) => f.init);
    },
    async onAuthStateChanged(user?: User | null) {
      this.uid = user?.uid;
      this.displayName = user?.displayName;
      this.email = user?.email;
      this.photoUrl = user?.photoURL;

      if (this.uid) {
        sentry.setUser({
          uid: this.uid!,
          email: this.email!
        });
      } else {
        sentry.clearUser();
      }

      this.init = true;
    }
  },
  getters: {
    loggedIn: (state) => !!state.uid
  }
});

export default useAuthStore;
